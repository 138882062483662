<template>
  <div class="venueChooser">
    <a href="/" class="smallLogo"></a>
    <div class="settingsTopbar">
      <a style="cursor: pointer" @click="logout">
        <i18n path="settings.logout" >
          <template v-slot:image>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.333 8-1.292 1.41L16.489 11H9v2h7.49l-1.45 1.58L16.334 16 20 12l-3.667-4zM5.778 6.556H12V5H5.778C4.8 5 4 5.7 4 6.556v10.888C4 18.3 4.8 19 5.778 19H12v-1.556H5.778V6.556z" fill="#0CBA4A" fill-rule="nonzero"/>
            </svg>
          </template>
          <!--
          <template v-slot:username>
            {{getUser.username}}
          </template>
          -->
        </i18n>
      </a>
    </div>
    <div class="container">
      <h1 class="venueHeadline">
          <i18n path="venueChooser.welcomeMessage" >
          <template v-slot:workspace>
            Work<span class="light">space</span>
          </template>
          <template v-slot:username>
            {{getUser.username}}
          </template>
        </i18n>
      </h1> 
      <div v-if="loading" class="venuesLoadingContainer">
        <LoadingAnimation />
      </div>
      <div v-else class="venuesContainer">
        
        <div class="venueTile" v-for="(venue, i) in venues" :key="i" @click="chooseVenue(venue)">
            <div>    
                <img v-if="venue.attributes.image.data != null" :src='strapiRootUrl+venue.attributes.image.data.attributes.url'>
            </div>
            <h2>{{venue.attributes.title}}</h2>
        </div>
      </div>    
    </div>
  </div>
  
</template>
<script>

import DataService from '@/services/StrapiService'
import { mapGetters } from 'vuex'
import LoadingAnimation from '@/components/LoadingAnimation.vue'

export default {
    name: 'VenueChooser',
    created () {
      DataService.getBottleSizes().then((response) => {
          this.$store.commit('setBottleSizes', response['data'].data)
      }).catch((err) => {
          console.log(err)
      })

      DataService.getCurrencies().then((response) => {
          this.$store.commit('setCurrencies', response['data'].data)
      }).catch((err) => {
          // handle error
          console.log(err)
      })
      
      DataService.getVenues().then((response) => {
        this.venues = response['data'].data
      }).catch((err) => {
        DataService.handleError(err, this.$store, this.$router)
      })
    },
    data() {
        return {
          loading: false,
          strapiRootUrl: DataService.getRootServerUrl(),
          venues: [],
        }
    },
    components: {
      LoadingAnimation
    },
    computed: {
      ...mapGetters(['getVenue', 'getUser', 'isInventoryOnly'])
    },
    methods: {
      logout() {
      this.$router.push('/login')
      this.$store.dispatch('logout')
      },
      chooseVenue(venue) {
        this.loading = true
        DataService.getVenueDetail(venue.id).then((response) => {
          var venueData = response['data'].data
          if (venueData.attributes.glassAmountPerBottle == null) {
            venueData.attributes.glassAmountPerBottle = []
          }
          for (var i = 0; i < venueData.attributes.wineSuppliers.data.length; i++) {
            if (venueData.attributes.wineSuppliers.data[i].attributes.address == null) {
              venueData.attributes.wineSuppliers.data[i].attributes.address = {
                street: null,
                city: null,
                zip: null,
                name: null
              }
            }
          }
          this.$store.commit('updateVenue', venueData)
          if (this.isInventoryOnly) {
            this.$router.push('/inventories')
          } else {
            this.$router.push('/')
          }
        }).catch((err) => {
          DataService.handleError(err, this.$store, this.$router)
        })
      }
    }
}
</script>